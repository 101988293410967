import axios from "axios";
import "./Filter.css";
import { useEffect, useState } from "react";
import SelectDropdown from "../../../common/SelectDropdown";
import MarkSelector from "./MarkSelector/MarkSelector";
import MillageSelector from "./MarkSelector/MillageSelector";

const Filter = ({setLoading, setErrors, setCarsList, setTotal, setUseFilter, filterPage, useFilter}) => {
    const date = new Date();
    const [filterOptions, setFilterOptions] = useState({
        vehicle_type: "all", 
        damage: "all", 
        odometer: "0 TO 9999999", 
        year: `1920 TO ${date.getFullYear()}`, 
        make: "all", 
        make_code: "all", 
        model: "all", 
        sort: ["bid_flag desc", "auction_date_type desc", "current_high_bid asc"], 
        page: 1, 
        limit: 20, 
        engine: "all", 
        fuel: "all", 
        transmission: "all", 
        drive_wheels: "all", 
        sell_type: "all"
    });
    const [marks, setMarks] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedMarks, setSelectedMarks] = useState([]);

    const loaders = {
        async filterResult(page){
            setUseFilter(true);
            try{
                const response = await axios.post('/api/auction/copart/search-filter', {...filterOptions, page: page});
                setLoading(false);
                setCarsList((prev) => ([...prev, ...response.data.data]));
                setTotal(response.data.total)
            }catch(err){
                setErrors((prev) => ({...prev, carLoading: true}));
            }
        },
        async getMakers(){
            const response = await axios.post('/api/vehicles/make');
            const data = response.data.data[0].make.filter((car) => car.type === "AUTOMOBILE");
            setMarks(data.sort());
        },
        async getModels(){
            const response = await axios.post('/api/vehicles/models');
            const data = response.data.data[0].vehicleMakeandModels.filter((car) => car.type === "AUTOMOBILE");
            setModels(data);
        }
    }

    useEffect(() => {
        loaders.getMakers();
        loaders.getModels();
    }, []);

    useEffect(() => {
        if(useFilter){
            setLoading(true);
            loaders.filterResult(filterPage);
        }
    }, [filterPage])

    return(
        <div className="catalogue-page-filter-container">
            <div className="catalogue-filter-row">
                <div className="filters-items-list">
                    <div className="catalogue-filter-item-box">
                        <MarkSelector 
                            active={filterOptions.make}
                            data={marks}
                            parameterName={"description"}
                            onChangeFunction={(data) => {
                                if(data === "default"){
                                    setFilterOptions((prev) => ({...prev, make_code: "all", make: "all", model: "all"}));
                                    setSelectedModels([]);
                                }else{
                                    const mark_code = data.code;
                                    const selected_mark = data.description;
                                    setFilterOptions((prev) => ({...prev, make_code: mark_code, make: selected_mark}));
                                    const maker_name = data.code;
                                    const selected_mark_models = models.filter((model) => model.group === maker_name);
                                    setSelectedModels(selected_mark_models);
                                }
                            }}
                            placeHolder={"მწარმოებელი"}
                            disabled={false}
                            disabledBtnTitle={""}
                            setSelectedMarks={setSelectedMarks}
                            selectedMarks={selectedMarks}
                            type="mark"
                        />
                    </div>
                    <div className="filter-divider"></div>
                    <div className="catalogue-filter-item-box">
                        <MarkSelector 
                            active={filterOptions.model}
                            data={models}
                            parameterName={"description"}
                            onChangeFunction={(data) => {
                                if(data === "default"){
                                    setFilterOptions((prev) => ({...prev, model: "all"}));
                                }else{
                                    const selected_model = data.description;
                                    setFilterOptions((prev) => ({...prev, model: selected_model}));
                                }
                            }}
                            placeHolder={"მოდელი"}
                            disabled={filterOptions.make === "all"}
                            disabledBtnTitle={"Please select make"}
                            type="models"
                        />
                    </div>
                    <div className="filter-divider"></div>
                    <div className="catalogue-filter-item-box">
                        <MillageSelector 
                            active={filterOptions.make}
                            data={models}
                            parameterName={"description"}
                            onChangeFunction={(data) => {
                                if(data === "default"){
                                    setFilterOptions((prev) => ({...prev, model: "all"}));
                                }else{
                                    const selected_model = data.description;
                                    setFilterOptions((prev) => ({...prev, model: selected_model}));
                                }
                            }}
                            placeHolder={"გარბენი"}
                            disabled={false}
                            disabledBtnTitle={"Please select make"}
                        />
                    </div>
                    <div className="filter-divider"></div>
                    <div className="catalogue-filter-item-box">
                        <SelectDropdown 
                            active={filterOptions.make}
                            data={models}
                            parameterName={"description"}
                            onChangeFunction={(data) => {
                                if(data === "default"){
                                    setFilterOptions((prev) => ({...prev, model: "all"}));
                                }else{
                                    const selected_model = data.description;
                                    setFilterOptions((prev) => ({...prev, model: selected_model}));
                                }
                            }}
                            placeHolder={"წელი"}
                            disabled={false}
                            disabledBtnTitle={"Please select make"}
                        />
                    </div>
                    <div className="filter-divider"></div>
                    <div className="catalogue-filter-item-box">
                        <SelectDropdown 
                            active={filterOptions.make}
                            data={models}
                            parameterName={"description"}
                            onChangeFunction={(data) => {
                                if(data === "default"){
                                    setFilterOptions((prev) => ({...prev, model: "all"}));
                                }else{
                                    const selected_model = data.description;
                                    setFilterOptions((prev) => ({...prev, model: selected_model}));
                                }
                            }}
                            placeHolder={"ფასი"}
                            disabled={false}
                            disabledBtnTitle={"Please select make"}
                        />
                    </div>
                    <div className="filter-divider"></div>
                    <div className="additional-filter-button">
                        <img src="/static/icons/filter-icon.svg" alt="filter" />
                        <span>დამატებითი ფილტრი</span>
                    </div>
                </div>
                <button onClick={() => {
                    setLoading(true);
                    setCarsList([]);
                    loaders.filterResult();
                }} className="filter-search-button">ძებნა</button>
            </div>
        </div>
    )
}

export default Filter