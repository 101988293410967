import axios from "axios"
import { useEffect, useState } from "react"
import VehicleList from "../components/Catalogue/VehicleList/VehicleList";
import Filter from "../components/Catalogue/Filter/Filter";
import Pagination from '@mui/material/Pagination';

const Catalogue = ({setSelectedLot}) => {
    const [carsList, setCarsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagerConfig, setPagerConfig] = useState({page: 1, limit: 20, sort_by: ""});
    const [total, setTotal] = useState("0");
    const [errors, setErrors] = useState({carLoading: false});
    const [useFilter, setUseFilter] = useState(false);

    const loaders = {
        async cars(){
            try{
                const response = await axios.post('/api/auction/copart/vehicle-finder', pagerConfig);
                setLoading(false);
                setCarsList([...response.data.data]);
                setTotal(response.data.total);
            }catch(err) {
                setErrors((prev) => ({...prev, carLoading: true}));
            }
        }
    }

    useEffect(() => {
        if(!useFilter){
            loaders.cars();
        }
    }, [pagerConfig]);

    return(
        <div className="catalogue-page-container">
            <span className="page-title">კატალოგი <img src="/static/icons/right-arrow.svg" alt="right-arrow"/></span>
            <Filter
                setLoading={setLoading}
                setCarsList={setCarsList}
                setTotal={setTotal}
                setErrors={setErrors}
                setUseFilter={setUseFilter}
                filterPage={pagerConfig.page}
                useFilter={useFilter}
            />
            <VehicleList 
                cars={carsList} 
                setCats={setCarsList} 
                setPagerConfig={setPagerConfig}
                loading={loading}
                setLoading={setLoading}
                pagerConfig={pagerConfig}
                setSelectedLot={setSelectedLot}
            />
            <div className="catalogue-pagination-container">
                <Pagination count={parseInt(total / pagerConfig.limit)} page={pagerConfig.page} onChange={(event, val) => {
                    setPagerConfig((prev) => ({...prev, page: val}));
                }} />
            </div>
        </div>
    )
}

export default Catalogue