import { Link } from "react-router-dom/cjs/react-router-dom.min"
import "../stylesheet/VehicleDetails.css"
import { useEffect, useState } from "react"
import axios from "axios";

const VehicleDetails = ({currentLot}) => {
    const [data, setData] = useState({});
    const lot = window.location.pathname.slice(9, 17);
    const [images, setImages] = useState({count: 0, list: []});
    const [activeImage, setActiveImage] = useState(0);

    useEffect(() => {
        // axios.get(`/api/auction/copart/car-images/${lot}`)
        //     .then(() => {

        //     })
        //     .catch(() => {
                
        //     })
        axios.get(`/api/auction/copart/car-details/${lot}`)
            .then((res) => {
                const imagesData = res.data.data.imagesList;
                console.log(imagesData.content);
                setImages({count: imagesData.totalElements, list: [...imagesData.content]});
                setData({...res.data.data.lotDetails})
            })
            .catch(() => {
                
            })
    }, []);

    const handlers = {
        nextSlide(){
            setActiveImage((prev) => {
                const nextSlide = prev < images.count - 1 ? prev + 1 : 0;
                return nextSlide
            })
        },
        prevSlide(){
            setActiveImage((prev) => {
                const prevSlide = prev == 0 ? images.count - 1 : prev - 1;
                return prevSlide
            })
        }
    }

    return (
        <div className="vehicle-details-page-container">
            <div className="vehicle-details-page-content">
                <div className="pager-history">
                    <Link to="/">მთავარი</Link>
                    <span> / </span>
                    <Link to="/catalogue">კატალოგი</Link>
                    <span> / </span>
                    <span>{data.mkn} {data.lmg}</span>
                </div>
                <h1>{data.mkn} {data.lm}</h1>
                <div className="vehicle-details-columns-container">
                    <div className="vehicle-details-images-carousel-slider vehicle-details-column">
                        <span className="vehicle-details-lot-number">LOT# {lot}</span>
                        <div className="vehicle-details-images-carousel-slider-box" style={{backgroundImage: `url(${typeof images.list[activeImage] != "undefined" && images.list[activeImage].highResUrl})`}}>
                            <button onClick={handlers.prevSlide} className="slide-switcher-button"><img src="/static/icons/next.svg" alt="next" style={{transform: `rotate(180deg)`}}/></button>
                            <button onClick={handlers.nextSlide} className="slide-switcher-button"><img src="/static/icons/next.svg" alt="next"/></button>
                        </div>
                        <div className="vehicle-details-carousel-items-list">
                            <div className="vehicle-details-carousel-items-list-box" style={{marginLeft: activeImage < 3 ? "0" : `${(activeImage - 2) * -222}px` }}>
                                {
                                    images.count > 0 && images.list.map((img, i) => {
                                        return(
                                            <div onClick={() => setActiveImage(i)} className={`vehicle-details-carousel-list-item ${activeImage == i ? "list-active-color-border" : ""}`} style={{backgroundImage: `url('${img.fullUrl}')`}}></div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="vehicle-details-car-condition-container">
                            <h3 className="vehicle-details-condition-title">მდგომარეობა</h3>
                            <div className="vehicle-details-condition-boxes">
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/primary-damage.svg" alt="primary-damage"/>
                                    <span>დაზიანება</span>
                                    <strong>{data.dd}</strong>
                                </div>
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/engine.svg" alt="primary-damage"/>
                                    <span>მანქანის მდგომარეობა</span>
                                    <strong>{data.lcd}</strong>
                                </div>
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/secondary-damage.svg" alt="primary-damage"/>
                                    <span>მეორადი დაზიანება</span>
                                    <strong>{data.dd}</strong>
                                </div>
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/drive-wheels.svg" alt="primary-damage"/>
                                    <span>წამყვანი თვლები</span>
                                    <strong>{data.drv}</strong>
                                </div>
                                <div className="vehicle-details-condition-box">
                                    <img src="/static/icons/calendar.svg" alt="primary-damage"/>
                                    <span>წელი</span>
                                    <strong>{data.lcy}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="vehicle-details-information-block vehicle-details-column">
                        <div className="vehicle-details-share-and-save">
                            <div className="vehicle-details-share">
                                <span>გაზიარება</span>
                                <button className="share-and-save-buttons"><img src="/static/icons/share.svg" alt="share"/></button>
                            </div>
                            <div className="vehicle-details-save">
                                <span>შენახვა</span>
                                <button className="share-and-save-buttons"><img src="/static/icons/save.svg" alt="share"/></button>
                            </div>
                        </div>
                        <div className="vehicle-details-bid-section">
                            <div className="vehicle-details-auction-information">
                                <div className="vehicle-details-bid-info">
                                    <p className="vehicle-details-current-bid-title">მიმდინარე ფასი</p>
                                    <p className="vehicle-details-current-bid">${typeof data.dynamicLotDetails != "undefined" && data.dynamicLotDetails.currentBid}</p>
                                </div>
                                <div className="vehicle-details-auction-start-date">
                                    <p className="vehicle-details-auction-date-title">აუქციონის დაწყების დრო</p>
                                    <p className="vehicle-details-auction-date">3 დღე 10:00 საათი</p>
                                </div>
                                <button className="place-pre-bid-button">გაყინე დეპოზიტი სათამაშოდ</button>
                                <button className="boy-now-button">იყიდე ახლავე</button>
                            </div>
                        </div>
                        <div className="vehicle-details-car-information-block">
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/vin.svg" alt="VIN" /> <p className="vehicle-details-car-info-item-title">VIN</p></div>
                                <p className="vehicle-details-car-info-item-value">{data.fv}</p>
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/car.svg" alt="Veh type" /> <p className="vehicle-details-car-info-item-title">მანქანის ტიპი</p></div>
                                <p className="vehicle-details-car-info-item-value">{data.vehTypDesc}</p>
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/calendar-black.svg" alt="Year" /> <p className="vehicle-details-car-info-item-title">წელი</p></div>
                                <p className="vehicle-details-car-info-item-value">{data.lcy}</p>
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/fuel.svg" alt="Fuel" /> <p className="vehicle-details-car-info-item-title">საწვავის ტიპი</p></div>
                                <p className="vehicle-details-car-info-item-value">{data.ft}</p>
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/transmission.svg" alt="Transmission" /> <p className="vehicle-details-car-info-item-title">ტრანსმისია</p></div>
                                <p className="vehicle-details-car-info-item-value">{data.tmtp}</p>
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/drive-black.svg" alt="Drive" /> <p className="vehicle-details-car-info-item-title">წამყვანი თვლები</p></div>
                                <p className="vehicle-details-car-info-item-value">{data.drv}</p>
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/vin.svg" alt="VIN" /> <p className="vehicle-details-car-info-item-title">ბატარეა</p></div>
                                <p className="vehicle-details-car-info-item-value">{data.fv}</p>
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/door.svg" alt="Doors" /> <p className="vehicle-details-car-info-item-title">კარი</p></div>
                                <p className="vehicle-details-car-info-item-value">4/5</p>
                            </div>
                            <div className="vehicle-details-car-info-item-divider"></div>
                            <div className="vehicle-details-car-info-item">
                                <div><img src="/static/icons/color.svg" alt="Color" /> <p className="vehicle-details-car-info-item-title">ფერი</p></div>
                                <p className="vehicle-details-car-info-item-value">{data.clr}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleDetails