import "./Banner.css"

const Banner = () => {
    return(
        <div className="banner-content-container">
            <div className="banner-container-circle"></div>
            <p className="banner-subtitle">შეარჩიე ავტომობილი და იყიდე სასურველ ფასად</p>
            <h1 className="banner-title">ჩამოიყვანე მანქანა საუკეთესო პირობით საქართლოში</h1>
        </div>
    )
}

export default Banner