import AutoAuctionBanner from "../components/MainPage/AutoAuctionBanner/AutoAuctionBanner"
import Banner from "../components/MainPage/Banner"
import PopularVehicles from "../components/MainPage/PopularVehicles/PopularVehicles"
import VehicleTypeSelector from "../components/MainPage/VehicleTypeSelector/VehicleTypeSelector"
import WhyUs from "../components/MainPage/WhyUs/WhyUs"

const MainPage = () => {
    return(
        <div className="main-page-container">
            <Banner />
            <img src="/static/banner-car.png" className="banner-car-image" alt="banner-vehicle"/>
            <div className="main-page-content">
                <VehicleTypeSelector />
                <WhyUs />
                <AutoAuctionBanner />
                <PopularVehicles />
            </div>
        </div>
    )
}

export default MainPage