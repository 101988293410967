import { useState } from "react";

const MillageSelector = ({active, data, onChangeFunction, parameterName, placeHolder, disabled, disabledBtnTitle, type}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [millage, setMillage] = useState({from: "0", to: "999999"})

    return(
        <div className="form-control-select">
            <div className={`form-control-select-box ${disabled ? 'disabled-dropdown' : ''}`} title={disabled ? disabledBtnTitle : ""} onClick={() => {
                    if(!disabled) setShowDropdown(!showDropdown);
                }}>
                    <p>{active === "all" ? placeHolder : active}</p>
                    <img src="/static/icons/down-icon.svg" alt="down" className="select-dropdown-icon" />
            </div>
            {(showDropdown) && <>
                <div className="target-box"></div>
                <div className="form-control-select-dropdown millage-selector">
                    <p className="millage-dropdown-title">გარბენი</p>
                    <div className="millage-input-range-content">
                        <div className="millage-input-box">
                            <span>დან</span>
                            <input value={`₾${millage.from}`} onChange={(e) => setMillage((prev) => ({...prev, from: e.target.value.replace("₾", "")}))} type="text" className="no-border"/>
                        </div>
                        <div className="millage-input-box">
                            <span>მდე</span>
                            <input value={`₾${millage.to}`} onChange={(e) => setMillage((prev) => ({...prev, to: e.target.value.replace("₾", "")}))} type="text" className="no-border"/>
                        </div>
                    </div>
                    <div className="range-slider-two-switcher">
                        <div className="range-slider-line-empty"></div>
                        <img src="/static/icons/switcher.svg"/>
                        <img src="/static/icons/switcher.svg"/>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default MillageSelector