import { useState } from "react";

const MarkSelector = ({active, data, onChangeFunction, parameterName, placeHolder, disabled, disabledBtnTitle, type}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    return(
        <div className="form-control-select">
            <div className={`form-control-select-box ${disabled ? 'disabled-dropdown' : ''}`} title={disabled ? disabledBtnTitle : ""} onClick={() => {
                    if(!disabled) setShowDropdown(!showDropdown);
                }}>
                    <p>{active === "all" ? placeHolder : active}</p>
                    <img src="/static/icons/down-icon.svg" alt="down" className="select-dropdown-icon" />
            </div>
            {(showDropdown && !disabled) && <>
                <div className="target-box"></div>
                <div className="form-control-select-dropdown mark-selector-dropdown">
                    {
                        type == "mark" && <>
                            <p className="top-brands-title">Top ბრენდები</p>
                            <div className="top-brands-list">
                                <div className="top-brands-item"><img src="/static/icons/aston-martin.svg"/></div>
                                <div className="top-brands-item"><img src="/static/icons/ford.svg"/></div>
                                <div className="top-brands-item"><img src="/static/icons/mercedes.svg"/></div>
                                <div className="top-brands-item"><img src="/static/icons/subaru.svg"/></div>
                                <div className="top-brands-item"><img src="/static/icons/audi.svg"/></div>
                            </div>
                        </>
                    }
                    <div onClick={() => {
                                onChangeFunction("default");
                                setShowDropdown(false);
                            }}  className="form-control-dropdown-item"><p className="form-control-dropdown-text">{placeHolder}</p></div>
                    {
                        data.map((d) => {
                            return <div onClick={() => {
                                onChangeFunction(d);
                                setShowDropdown(false);
                            }}  className="form-control-dropdown-item"><p className="form-control-dropdown-text">{d[parameterName]}</p></div>
                        })
                    }
                </div>
            </>}
        </div>
    )
}

export default MarkSelector