import { Link } from "react-router-dom/cjs/react-router-dom";
import "./VehicleList.css";
function formatNumberWithCommas(number) {
    return number.toLocaleString('en-US');
}

function convertMilliseconds(ms) {
  // Constants for time conversions
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const millisecondsInAnHour = 60 * 60 * 1000;
  const millisecondsInAMinute = 60 * 1000;

  // Calculate days
  const days = Math.floor(ms / millisecondsInADay);
  ms %= millisecondsInADay;

  // Calculate hours
  const hours = Math.floor(ms / millisecondsInAnHour);
  ms %= millisecondsInAnHour;

  // Calculate minutes
  const minutes = Math.floor(ms / millisecondsInAMinute);

  return { days, hours, minutes };
}

const VehicleList = ({
  cars,
  setPagerConfig,
  setSelectedLot,
  loading,
  setLoading
}) => {
  return (
    <div className="catalogue-content-container">
      <div className="vehicle-list-container">
        {cars.map((car) => {
          const auction_date = car.auction_date;
          const time_left_in_ms = auction_date - (+ new Date());
          const time_left = convertMilliseconds(time_left_in_ms);
          return (
            <Link className="cars-items-card-box" key={car.lot} to={`/vehicle/${car.lot}`} onClick={() => setSelectedLot(car.lot)}>
              <div className="vehicle-card-thumbnail" style={{backgroundImage: `url('${car.thumbnail}')`}}>
              </div>
              <div className="vehicle-card-details-box">
                <h6>{car.mark} {car.model}</h6>
                <p className="vehicle-card-car-name">{car.name}</p>
                <p className="vehicle-card-lot">LOT#: {car.lot}</p>
                <div className="vehicle-card-amount-content">
                  <div className="vehicle-card-current-bid">
                    <p className="vehicle-card-current-bid-title">მიმდინარე ფასი </p>
                    <p className="vehicle-card-current-bid-amount">{formatNumberWithCommas(car.currentBidAmount)} $</p>
                  </div>
                  <div className="vehicle-card-buy-now-price">
                    <p className="vehicle-card-buy-now-title">ახლავე ყიდვა </p>
                    <p className="vehicle-card-buy-now-amount">{formatNumberWithCommas(car.buyNowPrice)} $</p>
                  </div>
                </div>
                <div className="vehicle-card-car-details-hr">
                  <div className="vehicle-card-car-details-hr-val">
                    <img className="vehicle-card-car-details-hr-icon" src="/static/icons/millage.svg" alt="millage" />
                    <div className="vehicle-card-car-details-hr-value">
                      <p>{`${car.odometer} მ`}</p>
                    </div>
                  </div>
                  <div className="vehicle-card-car-details-hr-val">
                    <img className="vehicle-card-car-details-hr-icon" src="/static/icons/fuel.svg" alt="fuel" />
                    <div className="vehicle-card-car-details-hr-value">
                      <p>{car.fuelType}</p>
                    </div>
                  </div>
                  <div className="vehicle-card-car-details-hr-val">
                    <img className="vehicle-card-car-details-hr-icon" src="/static/icons/transmission.svg" alt="transmission" />
                    <div className="vehicle-card-car-details-hr-value">
                      <p>{car.transmission}</p>
                    </div>
                  </div>
                </div>
                <hr className="vehicle-card-hr"/>
                <div className="vehicle-card-auction-info">
                  <div className="vehicle-card-auction-date">
                    <img src="/static/icons/clock.svg" alt="clock"/>
                    <span>{time_left.days} დღე, {time_left.hours} სთ, {time_left.minutes}წთ</span>
                  </div>
                  <div className="vehicle-card-viewers">
                    <img src="/static/icons/eye.svg" alt="eye"/>
                    <span>1 ნახულობს</span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
        <div className="loading-container">
          {loading && <div className="catalogue-loader"></div>}
        </div>
      </div>
    </div>
  );
};

export default VehicleList;
