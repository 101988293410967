import { useState } from "react"

const SelectDropdown = ({active, data, onChangeFunction, parameterName, placeHolder, disabled, disabledBtnTitle}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    return(
        <div className="form-control-select">
            <div className={`form-control-select-box ${disabled ? 'disabled-dropdown' : ''}`} title={disabled ? disabledBtnTitle : ""} onClick={() => {
                    if(!disabled) setShowDropdown(!showDropdown);
                }}>
                    <p>{active === "all" ? placeHolder : active}</p>
                    <img src="/static/icons/down-icon.svg" alt="down" className="select-dropdown-icon" />
            </div>
            {(showDropdown && !disabled) && <div className="form-control-select-dropdown">
                <div onClick={() => {
                            onChangeFunction("default");
                            setShowDropdown(false);
                        }}  className="form-control-dropdown-item"><p className="form-control-dropdown-text">{placeHolder}</p></div>
                {
                    data.map((d) => {
                        return <div onClick={() => {
                            onChangeFunction(d);
                            setShowDropdown(false);
                        }}  className="form-control-dropdown-item"><p className="form-control-dropdown-text">{d[parameterName]}</p></div>
                    })
                }
            </div>}
        </div>
    )
}

export default SelectDropdown