/* eslint-disable */
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import './App.css';
import Header from './common/Header/Header';
import Catalogue from './pages/Catalogue';
import VehicleDetails from './pages/VehicleDetails';
import { useState } from 'react';
import MainPage from './pages/MainPage';

function App() {
    const [selectedLot, setSelectedLot] = useState("");
    return (
        <div className="App">
            <Header />
            <Switch>
                <Route path='/' exact><MainPage /></Route>
                <Route path='/catalogue'><Catalogue setSelectedLot={setSelectedLot} /></Route>
                <Route path='/vehicle/:lot'><VehicleDetails currentLot={selectedLot} /></Route>
            </Switch>
        </div>
    );
}

export default App;
