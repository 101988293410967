import "./WhyUs.css"

const WhyUs = () => {
    return(
        <div className="why-choose-us-container">
            <div className="why-us-container-background"></div>
            <h3 className="why-choose-us-title">რატომ ჩვენ?</h3>
            <div className="why-choose-us-list">
                <div className="why-choose-us-item">
                    <img src="/static/icons/price.png" alt="price"/>
                    <p className="why-choose-us-item-title">საუკეთესო ფასი</p>
                    <p className="why-choose-us-item-description">Carspace - ის ერთ-ერთი მთავარი კონკურენტული უპირატესობა სწორედ გამორჩეულად დაბალი ფასია</p>
                </div>
                <div className="why-choose-us-item">
                    <img src="/static/icons/support.png" alt="price"/>
                    <p className="why-choose-us-item-title">საუკეთესო ფასი</p>
                    <p className="why-choose-us-item-description">Carspace - ის ერთ-ერთი მთავარი კონკურენტული უპირატესობა სწორედ გამორჩეულად დაბალი ფასია</p>
                </div>
                <div className="why-choose-us-item">
                    <img src="/static/icons/benefits.png" alt="price"/>
                    <p className="why-choose-us-item-title">საუკეთესო ფასი</p>
                    <p className="why-choose-us-item-description">Carspace - ის ერთ-ერთი მთავარი კონკურენტული უპირატესობა სწორედ გამორჩეულად დაბალი ფასია</p>
                </div>
                <div className="why-choose-us-item">
                    <img src="/static/icons/clients.png" alt="price"/>
                    <p className="why-choose-us-item-title">საუკეთესო ფასი</p>
                    <p className="why-choose-us-item-description">Carspace - ის ერთ-ერთი მთავარი კონკურენტული უპირატესობა სწორედ გამორჩეულად დაბალი ფასია</p>
                </div>
            </div>
        </div>
    )
}

export default WhyUs