import "./VehicleTypeSelector.css"
import {Link} from 'react-router-dom';

const VehicleTypeSelector = () => {
    return(
        <div className="vehicle-type-selector-container">
            <div className="vehicle-type-selector-head">
                <h3 className="vehicle-type-selector-title">აირჩიე სასურველი ტიპი</h3>
                <Link className={"view-all-button"} to="/catalogue">ყველას ნახვა</Link>
            </div>
            <div className="vehicle-type-selector-items">
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/suv.png')`}}>
                    <p className="vehicle-type-selector-item-counter">23 cars</p>
                    <p className="vehicle-type-selector-item-type">SUV</p>
                </div>
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/sedan.png')`}}>
                    <p className="vehicle-type-selector-item-counter">60 cars</p>
                    <p className="vehicle-type-selector-item-type">Sedan</p>
                </div>
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/coupe.png')`}}>
                    <p className="vehicle-type-selector-item-counter">40 cars</p>
                    <p className="vehicle-type-selector-item-type">Coupe</p>
                </div>
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/hybrid.png')`}}>
                    <p className="vehicle-type-selector-item-counter">38 cars</p>
                    <p className="vehicle-type-selector-item-type">Hybrid</p>
                </div>
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/compact.png')`}}>
                    <p className="vehicle-type-selector-item-counter">88 cars</p>
                    <p className="vehicle-type-selector-item-type">Compact</p>
                </div>
            </div>
        </div>
    )
}

export default VehicleTypeSelector